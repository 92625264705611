<template>
    <div class="paper-detail">
        <transition name="fade">
            <div class="sliderBg" @click="$emit('update:ctrlPaperDetailPop', false)"></div>
        </transition>
        <transition name="slider-customer">
            <div class="sliderBody">
                <div class="targetMain">
                    <div class="paper-list">
                        <div class="top-ware">
                            <div class="left-avatar">
                                <img :src="gatAvatar(tempPaperDetail.avatar)" alt="img" class="avatar-img" />
                            </div>
                            <div class="right-main">
                                <div class="person">
                                    <div class="desc">
                                        <div class="name">{{tempPaperDetail.realName}}的{{tempPaperDetail.name}}</div>
                                        <div class="time">{{tempPaperDetail.createDate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-entend pull-right" v-if="tabData === 0">
                                <i @click.stop="moreControl(tempPaperDetail.realName, tempPaperDetail.ctId, tempPaperDetail.paperId, tempPaperDetail.detailId)" ref="IconEntend" class="icon-more iconfont"></i>
                                <van-action-sheet class="action-ctrl" v-model="isShowExtend" :actions="actionOption" cancel-text="取消" @select="handlerSelect" @cancel="isShowExtend = false" />
                            </div>
                        </div>
                        <div class="body-ware" ref="PaperContent">
                            <!-- 提取指标 -->
                            <div class="target" v-if="tempPaperDetail.hasOwnProperty('index')">
                                <template v-for="item in tempPaperDetail.index">
                                    <!-- jump 0:不可点击 1:可查看详情抽屉 不可跳转 2:可查看详情抽屉并可以跳转 moduleCode不为空 -->
                                    <span class="item" :class="item.jump !== 0 && item.count !== 0 ?'can-jump' : ''" @click="viewTargetDetail(item, tempPaperDetail.detailId)" :key="item.indexId">{{item.name}}&nbsp;{{item.count}}</span>
                                </template>
                            </div>
                            <!-- 日志字段 -->
                            <div class="fields">
                                <ul class="fields-ul">
                                    <template v-for="(item, index) in tempPaperDetail.details">
                                        <!-- 1：文本 2：数字 3：下拉 4：日期 5：外部联系人 100：备注 101：图片 102：附件 -->
                                        <li :key="index" class="fields-li" :class="{'annex': item.fieldType == '102'}" v-if="removeNull(item)">
                                            <span class="fields-name" v-if="item.fieldType != '101' && item.fieldType != '102'">{{item.name}}：</span>
                                            <span class="fields-desc fields-imgs" v-if="item.fieldType == '101'">
                                                <template v-if="item.content.length > 0">
                                                    <span @click="previewImage(item10)" class="iconBox" v-for="(item10, index10) in JSON.parse(item.content)" :key="index10">
                                                        <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                                                        <svg v-else class="iconSVG" aria-hidden="true">
                                                            <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                                                        </svg>
                                                    </span>
                                                </template>
                                            </span>
                                            <span class="fields-desc" v-else-if="item.fieldType == '102'">
                                                <template v-if="item.content.length > 0">
                                                    <!-- <span @click="previewImage(item10)" class="iconBox" v-for="(item10, index10) in JSON.parse(item.content)" :key="index10">
                                                        <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                                                        <svg v-else class="iconSVG" aria-hidden="true">
                                                            <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                                                        </svg>
                                                    </span> -->
                                                    <div class="show-img-annex">
                                                        <div @click="previewImage(item10)" class="iconBox-ware" v-for="(item10, index10) in JSON.parse(item.content)" :key="index10">
                                                            <div class="icon-img">
                                                                <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                                                                <svg v-else class="iconSVG" aria-hidden="true">
                                                                    <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                                                                </svg>
                                                            </div>
                                                            <div class="contentBox">
                                                                <div class="content">
                                                                    <p class="fileName ellipsis" v-html="item10.name"></p>
                                                                    <p class="fileInfo ellipsis" :size="Global.utils.byteCalc(item10.size)">
                                                                        {{getSize(item10.size)}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </span>
                                            <span class="fields-desc" v-else>{{item.content}}</span>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <!-- 是否已读 -->
                            <div class="status" v-if="tabData === 0">
                                <span :class="tempPaperDetail.status === 1 ? 'status__read' : 'status__unread'">{{tempPaperDetail.status === 1 ? '已读' : '未读'}}</span>
                            </div>
                            <div class="reading">
                                <!-- @click="viewRead(tempPaperDetail.paperId, tempPaperDetail.detailId)" -->
                                <span :class="tempPaperDetail.viewer.length > 0 ? 'light-times' : 'times'">{{tempPaperDetail.viewer.length}}人已读</span>
                                <div class="avatar-ware">
                                    <template v-for="(item, index) in tempPaperDetail.viewer">
                                        <div class="avatar-item" :key="index">
                                            <img class="avatar" :src="gatAvatar(item.avatar)" alt="img" />
                                            <div class="name">{{item.realName}}</div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'PaperDetail',
    props: {
        tabData: {
            type: Number
        },
        tempPaperDetail: {
            type: Object
        },
        companyObj: {
            type: Object
        }
    },
    components: {},
    data() {
        return {
            isShowExtend: false,
            actionOption: [
                {
                    name: '查看全部日志',
                    action: 'viewAll',
                    paperId: '',
                    detailId: '',
                    ctId: ''
                },
                {
                    name: '删除',
                    action: 'del',
                    paperId: '',
                    detailId: '',
                    ctId: ''
                }
            ],
            targetDetail: {
                targetTitle: '',
                valueList: [], // 数据值列表
                leftKeys: [], // 指标抽屉详情展示有点麻烦，字段名都不太一样，目前后台划分为左边2，右边0，有可能会添加
                rightKeys: [], // 右边展示字段
                isJump: 0, // 是否可跳转
                moduleCode: '', // 指标详情跳转参数
                billId: '' // 指标详情跳转参数
            }
        }
    },
    computed: {},
    watch: {
    },
    created() {
        this.tempPaperDetail.status === 0 && this.inputRead()
    },
    mounted() { },
    methods: {
        async inputRead() {
            try {
                let param = {
                    viewDetail: []
                }
                param.viewDetail.push({
                    paperId: this.tempPaperDetail.paperId,
                    detailId: this.tempPaperDetail.detailId
                })
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_viewer
                let res = await this.axios.post(url, param)
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    this.$emit('changeStatus', this.tempPaperDetail.paperId, this.tempPaperDetail.detailId)
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        removeNull(item) {
            // 备注 图片 附件 过滤空值
            if (item.fieldType == '100' || item.fieldType == '101' || item.fieldType == '102') {
                if (item.content.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        getSize(size) {
            if (size > 1024) {
                return parseFloat(size / 1024).toFixed(2) + ' M'
            } else {
                return parseFloat(size).toFixed(2) + ' KB'
            }
        },
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        async previewImage(item) {
            try {
                let suffix = this.substringSuffix(item.name)
                if (!this.Global.utils.filePreview.isSupport(suffix)) {
                    this.$toast('暂不支持该格式文件预览')
                    return
                }
                this.Global.utils.filePreview.open(item.url, suffix, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${suffix}`)
            } catch (error) {
                alert(error)
            }
        },
        gatAvatar(key) {
            return this.getGlobalImgSrc(key, '64x64')
        },
        async viewRead(paperId, detailId) {
            try {
                let param = {
                    paperId: paperId,
                    detailId: detailId
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_viewer
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        // this.$emit('changeStatus', paperId, detailId, res.data || [])
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async viewTargetDetail(item, detailId) {
            try {
                // jump 0:不可点击 1:可查看详情抽屉 不可跳转 2:可查看详情抽屉并可以跳转 moduleCode不为空
                if (item.jump === 0 || item.count === 0) return
                this.targetDetail = {
                    valueList: [],
                    leftKeys: [],
                    rightKeys: [],
                    isJump: 0,
                    moduleCode: '',
                    billId: ''
                }
                this.targetDetail.moduleCode = item.moduleCode
                this.targetDetail.isJump = item.jump
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_index_detail
                let res = await this.axios.get(url, {
                    params: { detailId: detailId, indexId: item.indexId }
                })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    // type 1: 不显示(模块跳转需要的id), 0: 右边(名称...), 2: 左边(显示时间...)
                    if (res.data.dataList && res.data.dataList.length > 0) this.targetDetail.valueList = [...res.data.dataList]
                    if (res.data.describe && res.data.describe.length > 0) {
                        let desc = [...res.data.describe]
                        this.targetDetail.leftKeys = desc.filter(v => v.type === 2).map(o => {
                            return o.key
                        })
                        this.targetDetail.rightKeys = desc.filter(v => v.type === 0).map(o => {
                            return o.key
                        })
                        this.targetDetail.billId = desc.filter(v => v.type === 1).map(o => {
                            return o.key
                        })[0]
                    }
                    this.targetDetail.targetTitle = item.name + ' ' + item.count
                    this.$emit('targetData', this.targetDetail)
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        moreControl(name, ctId, paperId, detailId) {
            this.actionOption[0].name = '查看' + name + '的全部日志'
            this.actionOption.forEach((item, index, arr) => {
                arr[index].ctId = ctId
                arr[index].paperId = paperId
                arr[index].detailId = detailId
            })
            this.isShowExtend = true
        },
        async handlerSelect(item) {
            if (item.action === 'viewAll') {
                // 查看全部日志
                this.$emit('findSomeoneAll', item.ctId)
                this.$emit('update:ctrlPaperDetailPop', false)
            } else if (item.action === 'del') {
                // 删除
                try {
                    let param = {
                        paperId: item.paperId,
                        detailId: item.detailId
                    }
                    let url = this.Global.config.apiBaseURL + this.Global.api.v1.handle_paper
                    let res = await this.axios.delete(url, { params: param })
                    res = res.data
                    if (res.code.toString() === this.Global.config.RES_OK) {
                        this.$emit('detailDelPaper', item.paperId, item.detailId)
                        this.$emit('update:ctrlPaperDetailPop', false)
                    } else {
                        this.$toast.fail(res.msg)
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.isShowExtend = false
        }
    },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
